<template>
  <ContainerHub class="hub-home">
    <template slot="header">
      <HubHeader
        :category="name"
        :titleTag="'h2'"
        :title="title" />
    </template>

    <template slot="body">
      <component
        :is="componentName"
        :display="displayType"
        :content="item"
        :key="`${componentName}-${index}`"
        v-for="(item, index) in items"
      />
    </template>
    <template v-if="name !== 'spotlight'" slot="footer">
      <BaseButton
        :icon="'arrow-right'"
        :icon-position="'right'"
        :color="name"
        :route="$t(`message.home.${name}.button.route`)"
      >
        {{ $t(`message.home.${name}.button.text`) }}
      </BaseButton>
    </template>
  </ContainerHub>
</template>

<script>
import BaseButton from '@base/BaseButton'
import ContainerHub from '@containers/ContainerHub'
import CardSpotlight from '@cards/spotlight/CardSpotlight'
import CardDocument from '@cards/document/CardDocument'
import CardEvent from '@cards/event/CardEvent'
import HubHeader from './HubHeader'

export default {
  name: 'HubHome',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    componentName () {
      return this.name === 'spotlight' ? 'CardSpotlight'
        : this.name === 'documents' ? 'CardDocument'
          : this.name === 'events' ? 'CardEvent'
            : 'div'
    },
    displayType () {
      return this.name === 'spotlight' ? 'large'
        : this.name === 'documents' ? 'medium'
          : this.name === 'events' ? 'medium'
            : 'large'
    }
  },
  components: {
    BaseButton,
    ContainerHub,
    HubHeader,
    CardSpotlight,
    CardDocument,
    CardEvent
  }
}
</script>

<style lang="scss">
  .hub-home{

  }
</style>
