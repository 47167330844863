<template>
  <div class="card-spotlight-news">
    <div class="card-spotlight-news__type">
      <ElementPreview type="news" :url="content.url" />
    </div>
    <div class="card-spotlight-news__description">
      <BaseTitle class="card-spotlight-news__title" tag="h3">
        <a :href="content.url">{{ content.title }}</a>
      </BaseTitle>
      <div class="card-spotlight-news__buttons">
        <BaseButton
          icon="arrow-right"
          icon-position="right"
          color="primary"
          :url="content.url">
            {{ $t('message.common.buttons.viewDetails') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@base/BaseTitle'
import BaseButton from '@base/BaseButton'
import ElementPreview from '@elements/ElementPreview'
export default {
  name: 'CardSpotlightNews',
  components: {
    BaseTitle,
    BaseButton,
    ElementPreview
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
  // CFR CardSpotlight

  .card-spotlight-news{
    @include media("small-medium"){
      padding: layout("spacing")/2;
    }
    @include media("large"){
      padding: layout("spacing");
    }

    display: flex;

    // Title

    &__title{
      a{
        margin-right: 1rem;
      }
    }

    // Description

    &__description{
      @include media("small-medium"){
        display: flex;
        align-items: center;
      }
    }
  }
</style>
