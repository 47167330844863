<template>
  <div class="card-spotlight-document">
    <div class="card-spotlight-document__type">
      <ElementPreview type="document" :url="content.url" :text="content.fileType" />
    </div>
    <div class="card-spotlight-document__description">
      <BaseTitle class="card-spotlight-document__title" tag="h3">
        <a :href="content.url">{{ content.title }}</a>
        <ElementTag class="is-yellow" v-if="content.size" :text="content.size" />
      </BaseTitle>
      <div class="card-spotlight-document__buttons">
        <BaseButton
          icon="zoom"
          icon-position="right"
          color="primary"
          :url="content.url">
            {{ $t('message.common.buttons.preview') }}
        </BaseButton>
        <span class="card-spotlight-document__condition" v-if="content.zipUrl">
          {{ $t('message.common.buttons.or') }}
        </span>
        <BaseButton
          icon="download"
          icon-position="right"
          color="primary"
          v-if="content.zipUrl"
          :url="content.zipUrl">
            {{ $t('message.common.buttons.download') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@base/BaseTitle'
import BaseButton from '@base/BaseButton'
import ElementPreview from '@elements/ElementPreview'
import ElementTag from '@elements/ElementTag'
export default {
  name: 'CardSpotlightDocument',
  components: {
    BaseTitle,
    BaseButton,
    ElementPreview,
    ElementTag
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
  // CFR CardSpotlight

  .card-spotlight-document{
    @include media("small-medium"){
      padding: layout("spacing")/2;
    }
    @include media("large"){
      padding: layout("spacing");
    }
    display: flex;

    // Title

    &__title{
      a{
        margin-right: 1rem;
      }
    }

    // Description

    &__description{
      @include media("small-medium"){
        display: flex;
        align-items: center;
      }
    }
  }
</style>
