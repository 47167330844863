<template>
  <div class="home-view" v-if="loaded">
    <HubHome :name="'spotlight'" :title="spotlightTitle" :items="spotlightItems" />
    <HubHome :name="'documents'" :title="documentsTitle" :items="documentsItems" />
    <HubHome :name="'events'" :title="upcomingTitle" :items="upcomingItems" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import updateBreadcrumb from '@mixins/updateBreadcrumb'
import HubHome from '@blocks/hubs/HubHome'

export default {
  name: 'HomeView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubHome
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      spotlightTitle: 'spotlight/getTitle',
      spotlightItems: 'spotlight/getItems',
      documentsTitle: 'documents/getTitle',
      documentsItems: 'documents/getItems',
      upcomingTitle: 'events/getUpcomingTitle',
      upcomingItems: 'events/getUpcomingItems'
    })
  },
  created () {
    this.fetchGetPage({ service: 'home' }).then(() => {
      this.loaded = true
    })
  },
  methods: {
    ...mapActions({
      fetchGetPage: 'page/fetchGetPage'
    })
  }
}
</script>

<style lang="scss">
  .home-view{
    padding-bottom: 3rem;
  }
</style>
