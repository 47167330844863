<template>
  <div class="card-spotlight">
    <div class="card-spotlight__inner">
      <component :is="componentName" :content="content" />
    </div>
  </div>
</template>

<script>
import CardSpotlightNews from './CardSpotlightNews'
import CardSpotlightDocument from './CardSpotlightDocument'
import CardSpotlightEvent from './CardSpotlightEvent'
// import CardSpotlightTweet from './CardSpotlightTweet'

export default {
  name: 'cardSpotlight',
  components: {
    CardSpotlightNews,
    CardSpotlightDocument,
    CardSpotlightEvent,
    // CardSpotlightTweet
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    display: {
      type: String,
      required: false,
      default: 'large'
    }
  },
  computed: {
    componentName () {
      return this.content.type === 'news' ? 'CardSpotlightNews'
        : this.content.type === 'document' ? 'CardSpotlightDocument'
          : this.content.type === 'event' ? 'CardSpotlightEvent'
            // : this.content.type === 'tweet' ? 'CardSpotlightTweet'
            : 'div'
    }
  }
}
</script>

<style lang="scss">
.card-spotlight{
  @media print {
    padding-left: 1rem;
    background-color: transparent;
    border-left: .6rem solid colors("spotlight");
    page-break-inside: avoid;
  }
  @include media("small-medium") {
    border-top: .6rem solid colors("spotlight");
  }
  @include media("large") {
    border-left: .6rem solid colors("spotlight");
  }

  width: 100%;
  border-radius: layout("border-radius");
  background-color: colors("foreground");

  .card-spotlight-news,
  .card-spotlight-document,
  .card-spotlight-event,
  .card-spotlight-tweet{
    > *{
      flex: 0 1 auto;
    }

    // Type

    &__type{
      @media print {
        margin-right: 2rem;
      }
      @include media("small-medium") {
        margin-right: layout("spacing")/2;
      }
      @include media("large") {
        margin-right: layout("spacing");
      }
      width: 7rem;
    }

    // Description

    &__description{
      flex: 1 1 auto;
    }

    // Buttons

    &__buttons{
      @media print {
        display: none !important;
      }
      @include media("small-medium") {
        display: none;
      }
      padding-top: layout("spacing")/2;

      .button{
        @include hover {
          color: white;
        }
      }
    }

    // Condition

    &__condition{
      margin: 0 layout("spacing")/2;
      color: rgba(black, .4);
      font-size: 1.4rem;
    }
  }

  .card-spotlight-tweet{
    &__inner{
      @media print {
        padding-top: 1rem;
        .preview{
          border-right: 0;
        }
      }
    }
    &__tweet{
      @media print {
        padding-right: 2rem;
      }
    }
  }
}
</style>
